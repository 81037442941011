.inicio{
    background-color: #4798D1;
    background-image: url('../../assets/graphics/raiodesol.svg');
    background-size: 100vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    background-position: 0 -160vw;
}

.equipamentos {

    width: 100%;
    height: calc(100vh - 60px);
    background-color: #FFFFFF

}

.partnerSystems{
    width: 100%;
    height: calc(100vh - 60px);
}

.footer {
    height: 28vh;
    width: 100%;
}